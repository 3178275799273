import { createSlice } from '@reduxjs/toolkit';
import { getSearchCockpit, updateCockpit } from '../../shared/services/searchCockpit';

export interface searchCockpit {
    prompt: string;
    travilyAPIKey: string
    searchAlgorithmType: number
    searchAlgorithmThreshold: number
    modelTemperature: number
    maxFilesReferencesForChat: number
    maxFilesReferencesForFileSearch: number
    maxWebSearchResult: number
    contentSegmentationType: number
    contentSegmentationCount: number
    //chatModel: number
    category: string
    contentSegmentationCountSummary: number
}

interface cockpit {
    loading: boolean;
    error: string | null;
    searchCockpit: searchCockpit | null;
    success: boolean;
}

const initialState: cockpit = {
    loading: false,
    error: null,
    searchCockpit:  null,
    success: false,
};

const teamSlice = createSlice({
    name: 'team',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loading = false;
            state.error = null;
            state.success = false;
        },

        resetSuccess: (state, { payload }) => {
            state.success = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSearchCockpit.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getSearchCockpit.fulfilled, (state, { payload }) => {
                state.searchCockpit = payload;
                state.loading = false;
                state.success = true;
            })
            .addCase(getSearchCockpit.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch teams';
            })



            .addCase(updateCockpit.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(updateCockpit.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.success = true;
                console.log(payload)
            })
            .addCase(updateCockpit.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch teams';
            })

    },
});

export const { resetState, resetSuccess } = teamSlice.actions;
export default teamSlice.reducer;

import { createSlice } from '@reduxjs/toolkit'
import { addPaymentMethod, checkOutsubcription, deletePaymentMethod, getHistory, getPaymentMethod, getSubcriptionPlan, getSubcriptionPlans, setDefaultPayment } from '../../shared/services/subcriptionPlan';
import { SubcriptionModel } from '../../shared/models/subcription-model';

const initialState: SubcriptionModel = {
    loading: false,
    subcriptionPlan: [],
    paymentHistory: [],
    paymentMethod: [],
    currentSubcriptionPlan: null,
    error: '',
    success: false,
    resetSuccess: false,
    progress: null,
}


const integrationSlice = createSlice({
    name: 'subcription',
    initialState,
    reducers: {
        resetState: (state) => {
            state.loading = false;
            state.error = '';
        },

        resetSuccess: (state, { payload }) => {
            state.success = payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSubcriptionPlans.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(getSubcriptionPlans.fulfilled, (state, { payload }) => {
                state.subcriptionPlan = payload
                state.loading = false;
                state.success = true;
            })
            .addCase(getSubcriptionPlans.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })

            .addCase(getSubcriptionPlan.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(getSubcriptionPlan.fulfilled, (state, { payload }) => {
                state.currentSubcriptionPlan = payload;
                state.loading = false;
                state.success = true;
            })
            .addCase(getSubcriptionPlan.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })


            .addCase(addPaymentMethod.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(addPaymentMethod.fulfilled, (state, { payload }) => {
                const exists = state.paymentMethod.some(
                    (method:any) => method.id === payload.id
                );
                
                if (!exists) {
                    const method = {
                        ...payload
                    };
                    state.paymentMethod?.push(method);
                }
                state.loading = false;
                state.success = true;
            })
            .addCase(addPaymentMethod.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })

            .addCase(checkOutsubcription.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(checkOutsubcription.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.success = true;
            })
            .addCase(checkOutsubcription.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })

            .addCase(getPaymentMethod.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(getPaymentMethod.fulfilled, (state, { payload }) => {
                const sortedPayload = payload.sort((a:any, b:any) => b.isDefault - a.isDefault);
                state.paymentMethod = sortedPayload;
                state.loading = false;
                state.success = true;
            })
            .addCase(getPaymentMethod.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })

            .addCase(getHistory.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(getHistory.fulfilled, (state, { payload }) => {
                state.paymentHistory = payload
                state.loading = false;
                state.success = true;
            })
            .addCase(getHistory.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })

            .addCase(setDefaultPayment.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(setDefaultPayment.fulfilled, (state, { payload }) => {
                state.paymentMethod = state.paymentMethod.map((method) => {
                    if (method.id === payload.id) {
                        return { ...method, isDefault: true ,isActive:true};
                    } else {
                        return { ...method, isDefault: false , isActive:false};
                    }
                });
                state.paymentMethod = state.paymentMethod.sort((a:any, b:any) => b.isDefault - a.isDefault);
                state.loading = false;
                state.success = true;
            })
            .addCase(setDefaultPayment.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })


            .addCase(deletePaymentMethod.pending, (state) => {
                state.loading = true
                state.error = null
                state.success = false
            })
            .addCase(deletePaymentMethod.fulfilled, (state, { payload }) => {
                state.paymentMethod  = state.paymentMethod.filter(method => method.id !== payload);
                state.loading = false;
                state.success = true;
            })
            .addCase(deletePaymentMethod.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message || 'Failed to login'
            })



    }
})


export const { resetState,
    resetSuccess } = integrationSlice.actions
export default integrationSlice.reducer
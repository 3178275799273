import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../core/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../core/components/ui/card";
import { Input } from "../../core/components/ui/input";
import { Label } from "../../core/components/ui/label";
import { resetSuccess } from "../../redux-store/reducers/dashboard-reducer";
import { AppDispatch, RootState } from "../../redux-store/stores/store";
import { RefreshAuthToken } from "../../shared/services";
import { createCompany } from "../../shared/services/dashboard";
import { updateUserInfo } from "../../redux-store/reducers/auth-reducers";

export const CreateWorkspacce = ({ onCompletion }: { onCompletion: () => void }) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm()
    const { success } = useSelector((state: RootState) => state.dashboard)
    const dispatch = useDispatch<AppDispatch>();

    const submitForm = (data: any) => {
        dispatch(createCompany(data)).then(() => {
                onCompletion();
        }).catch((error) => {
            console.error("Failed to create company:", error);
        });
    }

    const refresh = async () => {
        const refreshed = await RefreshAuthToken();
        if (refreshed) {
            dispatch(resetSuccess(false));
            dispatch(updateUserInfo());
        }
    }

    useEffect(() => {
        if (success) {
            refresh();
        }

    }, [success, reset])

    return (<div className="flex flex-col min-h-screen w-full items-center justify-center  bg-image bg-no-repeat bg-fixed bg-cover">
        <div className="flex h-full items-center justify-center">
            <Card className="mx-auto max-w-sm">
                <CardHeader>
                    <CardTitle className="text-2xl">Config your workspace</CardTitle>
                    <CardDescription>
                        You can use workspaces to organize well
                    </CardDescription>
                </CardHeader>
                <CardContent>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <div className="grid gap-4">
                            <div className="grid gap-2">
                                <Label htmlFor="email">Workspace name</Label>
                                <Input
                                    id="workspace"
                                    type="text"
                                    placeholder=""
                                    required
                                    {...register('name', {
                                        required: 'Workspace is required'
                                    })}
                                />
                                {errors.workspace && <p className="text-xs text-red-600">{errors.workspace.message?.toString()}</p>}
                            </div>
                            <Button type="submit" className="w-full">
                                Let's start
                            </Button>
                        </div>
                    </form>
                </CardContent>
            </Card>
        </div>
    </div>
    );
};

import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { CreateWorkspacce } from "../../pages/workspace/create-workspace";
import { fetchUserData, GetCompanyListLength } from "../../shared/services/manager";
import LogedLayout from "../layout/protected/logged-layout";
import { SubcriptionMethod } from "../../pages/subcription-plan/priceList";
import { useState } from "react";

export const ProtectedRoute = () => {
  const isAuthenticated = useSelector((state: any) => state.auth.isAuthenticated);
  const companies = GetCompanyListLength();
  const userDetails = fetchUserData();
  console.log(userDetails);

  const [isWorkspaceCreated, setIsWorkspaceCreated] = useState(false);

  const handleWorkspaceCreationComplete = () => {
    setIsWorkspaceCreated(true);
  };

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (companies === 0) {
    return isWorkspaceCreated ? (<SubcriptionMethod />) : (<CreateWorkspacce onCompletion={handleWorkspaceCreationComplete} />);
  }

  // Check if userDetails is null before accessing the subscription property
  if (userDetails === null) {
    return <Navigate to="*" replace />;
  }

  return userDetails?.subscription === "False" ? (<SubcriptionMethod />) : (<LogedLayout />);
};

import { loadStripe, Stripe, StripeElementChangeEvent } from '@stripe/stripe-js';
import { Elements, useElements, useStripe, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { AppDispatch, RootState } from '../../../redux-store/stores/store';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../core/components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../../../core/components/ui/dialog';
import { addPaymentMethod } from '../../../shared/services/subcriptionPlan';
import { getConfig } from '../../../shared/services/config-handler';

// const stripePromise = loadStripe('pk_test_51PpZi3L0ZqcmEiKxFbgYIKQkZMQoe8XeP21gvUIXdzDsHNcyAJnnVBAdzCulIg9QRfiZtbXv8wma60vEc0m3efHH000kcbJ3ck');
let stripePromise: Promise<Stripe | null> | null = null;
const stripeConfig = getConfig();

if (!stripeConfig || !stripeConfig.REACT_APP_STRIPE_KEY) {
  console.error("Stripe key is not loaded. Check your configuration.");
} else {
  stripePromise = loadStripe(stripeConfig.REACT_APP_STRIPE_KEY);
}



const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};
interface SaveCardFormProps {
  onSave: () => void;
}

const SaveCardForm : React.FC<SaveCardFormProps> = ({ onSave }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch: AppDispatch = useDispatch()
  const [cardError, setCardError] = useState('');
  const [expiryError, setExpiryError] = useState('');
  const [cvcError, setCvcError] = useState('');
  const [isCardComplete, setIsCardComplete] = useState(false);
  const [isExpiryComplete, setIsExpiryComplete] = useState(false);
  const [isCvcComplete, setIsCvcComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (!cardElement) {
      console.error('CardNumberElement is null');
      setIsLoading(false);
      return;
    }

    const cardholderName = (document.getElementById('cardholder-name') as HTMLInputElement).value;

    const result = await stripe.createToken(cardElement, {
      name: cardholderName,
    });

    if (result.error) {
      console.log(result.error.message);
    } else {


      const form = event.target as HTMLFormElement;
      form.reset();
      const cardExpiryElement = elements.getElement(CardExpiryElement);
      const cardCvcElement = elements.getElement(CardCvcElement);

      dispatch(addPaymentMethod(result.token.id))
      if (cardElement) cardElement.clear();
      if (cardExpiryElement) cardExpiryElement.clear();
      if (cardCvcElement) cardCvcElement.clear();
      onSave();
    }
    setIsLoading(false);
  };


  const handleCardChange = (event: StripeElementChangeEvent) => {
    setCardError(event.error ? event.error.message : '');
    setIsCardComplete(event.complete);
    validateForm();
  };

  const handleExpiryChange = (event: StripeElementChangeEvent) => {
    setExpiryError(event.error ? event.error.message : '');
    setIsExpiryComplete(event.complete);
    validateForm();
  };

  const handleCvcChange = (event: StripeElementChangeEvent) => {
    setCvcError(event.error ? event.error.message : '');
    setIsCvcComplete(event.complete);
    validateForm();
  };

  const validateForm = () => {
    return isCardComplete && isExpiryComplete && isCvcComplete && !cardError && !expiryError && !cvcError;
  };

  return (
    <div className="justify-start rounded-xl shadow-xl overflow-hidden ">
      <form onSubmit={handleSubmit}>
        <div className="bg-white p-6 border border-gray-300 rounded-[30px] shadow-xl">
          {/* <div className="flex justify-center mb-6 font-bold">
            <h3>Add Payment Method</h3>
          </div> */}

          <div className="mb-4">
            <label>Name on card</label>
            <input
              type="text"
              id="cardholder-name"
              name="cardholderName"
              placeholder="Cardholder's Name"
              className="border border-black rounded-lg p-2 w-full"
              required
            />
          </div>

          <div className="mb-6">
            <label>Card number</label>
            <div className="border border-black rounded-lg p-2 bg-white mb-3">
              <CardNumberElement options={CARD_ELEMENT_OPTIONS} onChange={handleCardChange} />
            </div>
            {cardError && <p className="text-red-500 text-sm">{cardError}</p>}

            <div className="flex flex-row grid grid-cols-2 gap-6">
              <div>
                <label>Expiration</label>
                <div className="border border-black rounded-lg p-2 bg-white">
                  <CardExpiryElement options={CARD_ELEMENT_OPTIONS} onChange={handleExpiryChange} />
                </div>
                {expiryError && <p className="text-red-500 text-sm">{expiryError}</p>}
              </div>

              <div>
                <label>CVC</label>
                <div className="border border-black rounded-lg p-2 bg-white">
                  <CardCvcElement options={CARD_ELEMENT_OPTIONS} onChange={handleCvcChange} />
                </div>
                {cvcError && <p className="text-red-500 text-sm">{cvcError}</p>}
              </div>
            </div>
          </div>

          <Button
            className={`${!stripe || !validateForm()}  text-white font-bold rounded-full w-full items-center border p-2`}
            type="submit"
            disabled={!stripe || !validateForm()} 
          >
            Save card
          </Button>
        </div>
      </form>
    </div>
  );
};


interface FileDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  files?: any[] | null;
  setFiles?: React.Dispatch<React.SetStateAction<any[] | null>>;
}


const PaymentPage: React.FC<FileDialogProps> = ({ open, setOpen }) => {
  const handleSaveCard = () => {
    // Close the dialog box after saving the card
    setOpen(false);
  };
  return (
    <div>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="sm:max-w-[700px]">
          <DialogHeader className="flex flex-col items-center justify-center">
            <DialogTitle className="font-bold text-2xl">Add Payment Method</DialogTitle>
            <DialogDescription>save your card for use to payment</DialogDescription>
          </DialogHeader>
          <Elements stripe={stripePromise}>
            <SaveCardForm onSave={handleSaveCard}/>
          </Elements>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PaymentPage;

import local from './envs/config.local.json'
import dev from './envs/config.dev.json'
import test from './envs/config.test.json'
import stage from './envs/config.stage.json'
import prod from './envs/config.prod.json'

let config: {
    REACT_APP_STRIPE_KEY: string,
    REACT_APP_API_URL: string
    REACT_APP_API_KEY: string
    REACT_APP_ENV: string
} | null = null;

export const loadConfig = () => {
    const hostname = window.location.hostname;
    config = local; 
    if (hostname.includes('dev')) {
        config = dev
    } else if (hostname.includes('stage')) {
        config = stage;
    } else if (hostname.includes('test')) {
        config = test;
    } else if (hostname.includes('app')) {
        config = prod;
    }
};

export const getConfig = () => {
    if (!config) {
        loadConfig()
    }
    return config
};
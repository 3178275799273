import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from ".";



export const createAPIKey= createAsyncThunk(
    'api/company/createKeys',
    async (_, { rejectWithValue }) => {
      const api = axiosInstance
      try {
        const { data } = await api.get('/company/create-key');
        return data;
      } catch (error: any) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      }
    }
  )

  export const deleteAPIKey= createAsyncThunk(
    'api/company/deleteApiKey',
    async (value:any, { rejectWithValue }) => {
      const api = axiosInstance
      try {
        const { data } = await api.delete(`/company/api-keys/${value}`);
        return data;
      } catch (error: any) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      }
    }
  )

  export const getKeyList= createAsyncThunk(
    'api/company/api-keys',
    async (_, { rejectWithValue }) => {
      const api = axiosInstance
      try {
        const { data } = await api.get('/company/api-keys');
        return data;
      } catch (error: any) {
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      }
    }
  )

import { memo } from 'react';
import { Card, CardDescription, CardHeader, CardTitle } from "../../../core/components/ui/card";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux-store/stores/store";
import { Button } from "../../../core/components/ui/button";
import { ArchiveRestore, Copy, PencilIcon, Plus, Trash } from "lucide-react";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "../../../shared/components/data-table/data-table";
import TeamMember from './teamMember';
import { Users } from '../../../shared/models/userManage-model';
import { deleteUser, getuserDetails, restoreUser } from '../../../shared/services/userManage';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../core/components/ui/tabs';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from '../../../core/components/ui/alert-dialog';
import TeamForm from './TeamForm';
import { getTeams, deleteTeam } from '../../../shared/services/teamService';
import { Team } from '../../../shared/models/team-model';
import { toast } from 'react-toastify';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../../core/components/ui/tooltip';

export const ManagementWorkspace = () => {
    const [open, setOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState('users');
    const [selectedUserTab, setSelectedUserTab] = useState('activeUser');
    const [editMode, setEditMode] = useState(false);
    const [selectedUser, setSelectedUser] = useState<Users | null>(null);
    const dispatch: AppDispatch = useDispatch();
    const { users } = useSelector((state: RootState) => state.userManage);
    const { teams } = useSelector((state: RootState) => state.teamManage);
    const getUserDetails = useRef(false);
    const { userInfo } = useSelector((state: RootState) => state.auth);
    const [openTeamDialog, setOpenTeamDialog] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const getTeamDetials = useRef(false);


    // user config..
    useEffect(() => {
        if (!getUserDetails.current) {
            dispatch(getuserDetails());
            getUserDetails.current = true;
        }
    }, [dispatch]);

    const handleDialog = () => {
        setOpen(!open);
    };

    const toggleEditMode = (userDetails: Users) => {
        setSelectedUser(userDetails);
        setEditMode(true);
        handleDialog();
    };


    const openCreateMode = () => {
        setSelectedUser(null);
        setEditMode(false);
        handleDialog();
    };


    const filterUsersByStatus = (status: number) => {
        return users.filter((user) => user?.userStatus === status);
    };

    const handleRemoveFile = (userId: number) => {
        dispatch(deleteUser(userId));
    };

    const handleRestore = (userId: number) => {
        dispatch(restoreUser(userId));
    };


    const handleCopyFile = (token: any) => {
        navigator.clipboard.writeText(token).then(() => {
            toast.success("Password copied to clipboard!")
        }).catch(err => {
            toast.error("Failed to copy token.");
        });
    }

    //Team config..
    useEffect(() => {
        if (!getTeamDetials.current) {
            dispatch(getTeams());
            getTeamDetials.current = true;
        }
    }, [dispatch]);

    const handleUserTabChange = (tab: string) => {
        setSelectedUserTab(tab);
    };

    const handleMainTabChange = (tab: string) => {
        setSelectedTab(tab);
    };


    const openCreateTeamDialog = () => {
        setSelectedTeam(null);
        setEditMode(false);
        setOpenTeamDialog(true);
    };


    const toggleEditTeam = (teamDetails: any) => {
        setSelectedTeam(teamDetails);
        setEditMode(true);
        setOpenTeamDialog(true);
    };

    const handleRemoveTeam = (team: any) => {
        dispatch(deleteTeam(team));
    };


    // Columns for Users Table
    const getActiveUserColumns = (handleRemoveFile?: (userId: number) => void, toggleEditMode?: (userDetails: Users) => void, handleRestore?: (userId: number) => void): ColumnDef<Users, any>[] => [
        {
            accessorKey: "fullName",
            header: ({ column }) => (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Name
                    <CaretSortIcon className="ml-2 h-4 w-4" />
                </Button>
            ),
            cell: memo(({ row }) => (
                <div className="flex ml-10">{row.getValue("fullName")}</div>
            )),
        },
        {
            accessorKey: "email",
            header: ({ column }) => (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    User Email
                    <CaretSortIcon className="ml-2 h-4 w-4" />
                </Button>
            ),
            cell: memo(({ row }) => (
                <div className="flex ml-10">{row.getValue("email")}</div>
            )),
        },
        {
            accessorKey: "role",
            header: ({ column }) => (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    User Role
                    <CaretSortIcon className="ml-2 h-4 w-4" />
                </Button>
            ),
            cell: memo(({ row }) => {
                const roleValue = row.getValue("role");
                const roleName = roleValue === 0 ? "Admin" : roleValue === 1 ? "User" : "Unknown";

                return (
                    <div className="flex ml-10 items-center">
                        <span>{roleName}</span>
                        {row.original.isOwner && (
                            <span className="ml-2 px-2 py-1 text-xs font-semibold text-white bg-blue-500 rounded-full">
                                Owner
                            </span>
                        )}
                    </div>
                );
            }),
        },
        {
            id: "actions",
            enableHiding: false,
            cell: memo(({ row }) => {
                const userDetails = row.original;
                const loggedInUserId = userInfo?.userId;
                const isUser = 'user';  // Example condition, adjust based on your data

                return (
                    <div className="flex flex-row items-center justify-end space-x-2">
                        {row.original.userStatus === 0 ? (
                            <>
                                {row.original.userInfo.tempPassword && (
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <Button
                                                    size={"icon"}
                                                    variant="outline"
                                                    className="border-green-500 rounded-full"
                                                    onClick={() => handleCopyFile(row.original?.userInfo?.tempPassword)}
                                                >
                                                    <Copy className="size-5" />
                                                </Button>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                               <span> user password </span>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                )}
                                <Button
                                    size={"icon"}
                                    variant="outline"
                                    className="border-blue-500 rounded-full"
                                    onClick={() => toggleEditMode && toggleEditMode(userDetails)

                                    }
                                >
                                    <PencilIcon className="size-5" color="black" />
                                </Button>
                                <ConfirmDeleteButton
                                    onConfirm={() => handleRemoveFile && handleRemoveFile(userDetails.userId)}
                                    disabled={userDetails.userId.toString() === loggedInUserId || !!row.original.isOwner}
                                    type={isUser}
                                />
                            </>
                        ) : row.original.userStatus === 1 ? (
                            <Button
                                size={"icon"}
                                variant="outline"
                                className="border-blue-500 rounded-full"
                                onClick={() => handleRestore && handleRestore(userDetails.userId)}
                            >
                                <ArchiveRestore className="size-5" color="black" />
                            </Button>
                        ) : null}
                    </div>
                );
            }),
        },
    ];


    // Columns for group Table
    const getTeamColumns = (toggleEditTeam: (teamDetails: any) => void, handleRemoveTeam: (teamId: any) => void): ColumnDef<Team, any>[] => [
        {
            accessorKey: "groupName",
            header: ({ column }) => (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Team Name
                    <CaretSortIcon className="ml-2 h-4 w-4" />
                </Button>
            ),
            cell: memo(({ row }) => (
                <div className="flex ml-10">{row.getValue("groupName")}</div>
            )),
        },
        {
            accessorKey: "users",
            header: "Users",
            cell: memo(({ row }) => (
                <div className="flex text-md font-semibold">
                    {row.original?.userGroupModels?.map((user: any) => user?.fullName).join(' , ')}
                </div>
            )),
        },
        {
            id: "actions",
            enableHiding: false,
            cell: memo(({ row }) => {
                const teamDetails = row.original;
                const isGroup = 'group';
                return (
                    <div className="flex flex-row items-center justify-end space-x-2">
                        <Button size={"icon"} variant="outline" className="border-blue-500 rounded-full" onClick={() => toggleEditTeam(teamDetails)}>
                            <PencilIcon className="size-5" color='black' />
                        </Button>
                        <ConfirmDeleteButton
                            onConfirm={() => handleRemoveTeam(teamDetails)}
                            disabled={false}
                            type={isGroup}
                        />
                    </div>
                );
            }),
        },
    ];

    return (
        <div className="flex flex-col w-full overflow-auto gap-6 min-h-[calc(100vh_-_theme(spacing.32))]">
            <Tabs onValueChange={handleMainTabChange} defaultValue="users">
                <TabsList className="bg-gray-100 p-2 rounded-lg flex space-x-2 justify-start w-full">
                    <TabsTrigger
                        value="users"
                        className="px-6 py-1 rounded-3xl text-gray-600 hover:bg-gray-200 data-[state=active]:bg-gray-300 data-[state=active]:text-black data-[state=active]:font-semibold"
                    >
                        Users
                    </TabsTrigger>
                    <TabsTrigger
                        value="teams"
                        className="px-6 py-1 rounded-3xl text-gray-600 hover:bg-gray-200 data-[state=active]:bg-gray-300 data-[state=active]:text-black data-[state=active]:font-semibold"
                    >
                        Teams
                    </TabsTrigger>
                </TabsList>

                {/* Users Section */}
                <TabsContent value="users" className="mt-4">
                    <Card className="w-full h-fit">
                        <CardHeader>
                            <CardTitle className="flex w-full flex-row items-start md:items-center justify-between">
                                <span className="text-xl font-bold">Users</span>
                                <Button size={"lg"} onClick={openCreateMode} className="space-x-2 mb-3">
                                    <Plus className="size-5" /> <span className="hidden md:flex">Add a User</span>
                                </Button>
                            </CardTitle>
                            <br />
                            <div>
                                <Tabs onValueChange={handleUserTabChange} defaultValue="activeUser">
                                    <TabsList className="flex">
                                        <TabsTrigger value="activeUser" className="w-[500px]">Active Users</TabsTrigger>
                                        <TabsTrigger value="suspendUser" className="w-[500px]">Suspended Users</TabsTrigger>
                                        <TabsTrigger value="deleteUser" className="w-[500px]">Deleted Users</TabsTrigger>
                                    </TabsList>

                                    <TabsContent value="activeUser" className="mt-4">
                                        <DataTable
                                            data={filterUsersByStatus(0)}
                                            getColumns={() => getActiveUserColumns(handleRemoveFile, toggleEditMode)}
                                        />
                                    </TabsContent>
                                    <TabsContent value="suspendUser" className="mt-4">
                                        <DataTable
                                            data={filterUsersByStatus(1)}
                                            getColumns={() => getActiveUserColumns(handleRemoveFile, toggleEditMode, handleRestore)}
                                        />
                                    </TabsContent>
                                    <TabsContent value="deleteUser" className="mt-4">
                                        <DataTable
                                            data={filterUsersByStatus(2)}
                                            getColumns={() => getActiveUserColumns(handleRemoveFile, toggleEditMode)}
                                        />
                                    </TabsContent>
                                </Tabs>
                            </div>
                        </CardHeader>
                    </Card>
                    <TeamMember
                        open={open}
                        setOpen={setOpen}
                        editMode={editMode}
                        user={selectedUser}
                        profileEdit={false}
                    />
                </TabsContent>

                {/* Teams Section */}
                <TabsContent value="teams" className="mt-4">
                    <Card className="w-full h-fit">
                        <CardHeader>
                            <CardTitle className="flex w-full flex-row items-start md:items-center justify-between">
                                <span className="text-xl font-bold">Teams</span>
                                <Button size={"lg"} onClick={openCreateTeamDialog} className="space-x-2">
                                    <Plus className="size-5" /> <span className="hidden md:flex">Add a Team</span>
                                </Button>
                            </CardTitle>
                            <br />
                            <div>
                                <DataTable
                                    data={teams}
                                    getColumns={() => getTeamColumns(toggleEditTeam, handleRemoveTeam)}
                                />
                            </div>
                        </CardHeader>
                    </Card>

                    <TeamForm
                        open={openTeamDialog}
                        setOpen={setOpenTeamDialog}
                        editMode={editMode}
                        team={selectedTeam}
                        users={users}
                    />
                </TabsContent>
            </Tabs>
        </div>
    );
};
const ConfirmDeleteButton = ({ onConfirm, disabled, type }: { onConfirm: () => void, disabled: boolean, type: any }) => {

    let message = '';
    if (type === 'user') {
        message = 'Are you sure you want to suspend this user?';
    } else if (type === 'group') {
        message = 'Are you sure you want to suspend this group?';
    }

    return (
        <AlertDialog>
            <AlertDialogTrigger asChild>
                <Button size={"icon"} variant="outline" className={`border-secondary rounded-full mr-5 ${disabled ? "pointer-events-none opacity-50" : ""}`} disabled={disabled}>
                    <Trash className="size-5" color='black' />
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                    <AlertDialogDescription>
                        <span>{message}</span>
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel> <Button variant="ghost">Cancel</Button></AlertDialogCancel>
                    <AlertDialogAction> <Button variant="default" onClick={onConfirm}>Suspend</Button></AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
}


import { useSelector } from "react-redux";
import { NavLink, Outlet } from "react-router-dom";
import { RootState } from "../../../redux-store/stores/store";
import { fetchCompanyList } from "../../../shared/services/manager";

const UserSettings = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth)
  const fetchCompany = fetchCompanyList();
  const companyDetails = fetchCompany.values().next().value;
  const { role } = companyDetails;


  const sections = [
    {
      link: 'general',
      title: 'General'
    },
    ...(role !== "1"
      ? [
        {
          link: 'integrations',
          title: 'Integration'
        },
        {
          link: 'payments',
          title: 'Payments'
        },
        {
          link: 'userManagement',
          title: 'Users & Teams'
        },
        {
          link: 'searchCockpit',
          title: 'Search Cockpit'
        },
      ]
      : []
    ),
    {
      link: 'APIkey',
      title: 'API Keys'
    },
  ];

  return (
    <main className="flex flex-col h-full w-full max-w-7xl mx-auto space-y-4 pt-10 px-4">
      <div className="flex flex-col gap-4">
        <div className="grid w-full">
          <h1 className="text-3xl font-extrabold">Settings</h1>
        </div>
        <div className=" flex flex-wrap w-full items-start">
          <nav className="flex flex-wrap text-muted-foreground w-full gap-2  items-center border-b-2 border-slate-200 pb-4">
            {sections.map((nav) => (
              <NavLink
                key={nav.link}  // Added key prop here
                to={nav.link}
                className={({ isActive }) => isActive ? "font-semibold text-primary bg-slate-200 px-6 py-1 rounded-3xl " : "px-6 py-1"}
              >
                {nav.title}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
      <div className="h-full overflow-auto">
        <Outlet />
      </div>
    </main>
  );
};

export default UserSettings;

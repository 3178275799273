import { ColumnDef } from "@tanstack/react-table";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../../core/components/ui/card"
import { Button } from "../../../core/components/ui/button";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { InfoIcon, Plus, Trash } from "lucide-react";
import { DataTable } from "../../../shared/components/data-table/data-table";
import { useEffect, useRef, useState } from "react";
import { history } from "../../../shared/models/subcription-model";
import { AppDispatch, RootState } from "../../../redux-store/stores/store";
import { useDispatch, useSelector } from "react-redux";
import PaymentPage from "./paymentMethod";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { deletePaymentMethod, getHistory, getPaymentMethod, getSubcriptionPlan, reactiveSubcription, removeSubcripption, setDefaultPayment } from "../../../shared/services/subcriptionPlan";
import { toast } from "react-toastify";

export const PaymetnsMethods = () => {
    const [open, setOpen] = useState(false);
    const getCardDetails = useRef(false);
    const getPaymentHistory = useRef(false);
    const getCurrentSubcriptionPlan = useRef(false);
    const dispatch: AppDispatch = useDispatch();
    const { paymentHistory, paymentMethod, currentSubcriptionPlan } = useSelector((state: RootState) => state.subcriptionPlan);
    const [localCurrentPlan, setLocalCurrentPlan] = useState(currentSubcriptionPlan);
    const progress = currentSubcriptionPlan?.planInfo?.addOnGbCount +  currentSubcriptionPlan?.planInfo?.totalAddonGbCount;
    const Total = currentSubcriptionPlan?.planInfo?.storageLimitation;
    const progressPercentage = ( progress/ Total) * 100;

    useEffect(() => {
        if (!getCurrentSubcriptionPlan.current) {
            dispatch(getSubcriptionPlan());
            getCurrentSubcriptionPlan.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (!getCardDetails.current) {
            dispatch(getPaymentMethod());
            getCardDetails.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (!getPaymentHistory.current) {
            dispatch(getHistory());
            getPaymentHistory.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        setLocalCurrentPlan(currentSubcriptionPlan);
    }, [currentSubcriptionPlan]);

    const handleDialog = () => {
        setOpen(!open)
    }

    const handleDeactiveCurrentPlan = (planId: number) => {
        dispatch(removeSubcripption(planId)).then((result) => {
            if (result.meta.requestStatus === "fulfilled") {
                setLocalCurrentPlan((prevPlan: any) => ({
                    ...prevPlan,
                    status: 4,
                }));
                toast.success("subcription plan deactivated successfully");
            }
        }).catch((error) => {
            toast.error('Error plan deactivating:', error);
        });
    }

    const handleReactiveCurrentPlan = (planId: number) => {
        dispatch(reactiveSubcription(planId)).then((result) => {
            if (result.meta.requestStatus === "fulfilled") {
                setLocalCurrentPlan((prevPlan: any) => ({
                    ...prevPlan,
                    status: 1,
                }));
                toast.success("subcription plan activated successfully");
            }
        }).catch((error) => {
            toast.error('Error plan activating:', error);
        });
    }

    const handleRemoveCard = (cardId: number) => {
        dispatch(deletePaymentMethod(cardId));
    };

    const handleDefaultCard = (cardId: number) => {
        dispatch(setDefaultPayment(cardId));
    };


    return (
        <div>
            <div className="flex flex-col px-4 md:px-6 lg:px-10 py-6 w-full">
                <div className="flex flex-col md:flex-row gap-2 w-full">
                    <Card className="shadow-xl flex flex-col w-full md:w-2/3 h-auto md:h-[370px]">
                        <CardHeader>
                            <CardTitle className="flex justify-between items-center">
                                <p className="text-lg md:text-xl font-bold">Payment Method</p>
                                <Button size="sm" type="submit" onClick={handleDialog} className="flex items-center">
                                    <Plus />
                                    <p className="hidden md:block">Add Payment Method</p>
                                </Button>
                            </CardTitle>
                            <CardDescription>
                                <span>Your workspace Payments details shown here</span>
                            </CardDescription>
                        </CardHeader>
                        <div className="flex flex-col overflow-hidden overflow-y-scroll">
                            {paymentMethod.length === 0 ? (
                                <div className="text-lg md:text-xl font-semibold flex justify-center items-center text-gray-600">
                                    <p>No added payment method</p>
                                </div>
                            ) : (
                                paymentMethod?.map((item) => (
                                    <CardContent key={item.id} className="flex flex-col w-full">
                                        <div className="flex flex-col justify-start items-start border rounded-lg w-full relative p-2">
                                            <p className="font-semibold">{item.info.brand}</p>
                                            <span className="font-semibold">**** **** **** {item.info.lastNumbers}</span>
                                            <p className="text-black">
                                                {item.info.expireMonth.toString().padStart(2, '0')}/{item.info.expireYear.slice(2)}
                                            </p>
                                            {item.isDefault && (
                                                <label className="absolute top-2 right-2 bg-blue-500 text-white text-xs p-1 rounded-lg font-bold">
                                                    Default
                                                </label>
                                            )}
                                            {!item.isDefault && (
                                                <div className="absolute right-14 top-7 text-sm text-black border rounded-full border-blue-300">
                                                    <Button variant="ghost" size={"sm"} className="border-secondary rounded-full" onClick={() => handleDefaultCard(item.id)}>
                                                        <span className="block text-xs md:hidden">Default</span> {/* Shown on mobile */}
                                                        <span className="hidden md:block">Set as Default</span> {/* Shown on larger screens */}
                                                    </Button>
                                                </div>
                                            )}
                                            {!item.isDefault && (
                                                <div className="absolute top-7 right-2 rounded-full">
                                                    <Button size="icon" variant="outline" className="border-secondary rounded-full ml-4" onClick={() => handleRemoveCard(item.id)}>
                                                        <Trash className="size-5" />
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    </CardContent>
                                ))
                            )}
                        </div>
                    </Card>

                    <div className="flex w-full md:w-1/3 ml-2 mr-2">
                        <div className="flex flex-col w-full bg-card rounded-xl shadow-xl p-4">
                            <div className="border-b mb-3">
                                <p className="text-lg md:text-xl font-bold">Subscription Plan</p>
                            </div>
                            <div className="flex flex-col h-auto p-2 bg-gray-100 border rounded-lg">
                                <h3 className="mb-4 mt-2 font-bold">{currentSubcriptionPlan?.planInfo?.name}</h3>
                                <div className="flex text-xs border rounded-lg p-1  border-black">
                                    <InfoIcon className="w-4 h-4 flex-none mr-1" />
                                    Cancel risk-free before your trial ends to avoid any charges. After the trial, a prepaid annual fee of $1,188 will be automatically billed, covering the entire year at a rate of $99 per month.
                                </div>
                                <div className="mt-4 flex flex-col relative items-center justify-center ">
                                    {localCurrentPlan?.status === 1 && (
                                        <Button variant="destructive" size={"lg"} onClick={() => handleDeactiveCurrentPlan(currentSubcriptionPlan?.subscriptionId)}>
                                            Deactivate
                                        </Button>
                                    )}
                                    {localCurrentPlan?.status === 4 && (
                                        <Button className="mt-2" size={"lg"} onClick={() => handleReactiveCurrentPlan(currentSubcriptionPlan?.subscriptionId)}>
                                            Reactivate
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex w-full md:w-1/3">
                        <div className="flex flex-col w-full bg-white rounded-xl shadow-xl p-3">
                            <div className="border-b mb-3">
                                <p className="text-lg md:text-xl font-bold">Monthly Usage Plan</p>
                            </div>
                            <div  className="flex justify-center items-center mt-4">
                                <div style={{ width: 150, height: 150 }}>
                                    <CircularProgressbar
                                        value={progressPercentage ?? 0}
                                        strokeWidth={15}
                                        styles={buildStyles({
                                            textColor: '#000',
                                            pathColor: progressPercentage >= 90 ? '#0b66ef' : '#0bcfef',
                                            trailColor: '#d6d6d6',
                                        })}
                                    />
                                    <div
                                        style={{
                                            position: 'relative',
                                            transform: 'translate(30%, -300%)',
                                            fontSize: '20px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {`${isNaN(Total) || Total === undefined ? 0 : Total}GB`}
                                    </div>
                                </div>
                            </div>

                            <div className="ml-2 mr-2 mt-5">
                                <div className="flex justify-between">
                                    <div className="flex items-center">
                                        <span className="w-3 h-3 bg-gray-400 rounded-full mr-2"></span>
                                        <span>Total</span>
                                    </div>
                                    <div className="font-bold"> {`${isNaN(Total) || Total === undefined ? 0 : Total}GB`}</div>
                                </div>
                                <div className="flex justify-between mt-2">
                                    <div className="flex items-center">
                                        <span className="w-3 h-3 bg-blue-500 rounded-full mr-2"></span>
                                        <span>Used Storage</span>
                                    </div>
                                    <div className="font-bold">{`${isNaN(progress) || progress === undefined ? 0 : progress}GB`}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Payment Details Section */}
                <div className="mt-6 w-full">
                    <Card className="shadow-xl">
                        <CardHeader>
                            <CardTitle>
                                <p className="text-lg md:text-xl font-bold">Payment Details</p>
                            </CardTitle>
                            <CardDescription>Your workspace Payments details shown here</CardDescription>
                        </CardHeader>
                        <CardContent className="flex flex-col items-center">
                            <div className="w-full">
                                <DataTable data={paymentHistory} getColumns={() => getColumns()} />
                            </div>
                        </CardContent>
                    </Card>
                </div>

                {open && <PaymentPage open={open} setOpen={setOpen} />}
            </div>
        </div>
    );
}


export const getColumns = (): ColumnDef<history, any>[] => [
    {
        accessorKey: "periodStarted",
        header: ({ column }) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Start Date
                    <CaretSortIcon className="ml-2 h-4 w-4" />
                </Button>
            )
        },
        cell: ({ row }) => {
            const dateValue = row.getValue("periodStarted") as string;
            const formattedDate = new Date(dateValue).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            });
            return <div className="lowercase flex ml-10">{formattedDate}</div>;
        },
    },
    {
        accessorKey: "periodEnd",
        header: ({ column }) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    End Date
                    <CaretSortIcon className="ml-2 h-4 w-4" />
                </Button>
            )
        },
        cell: ({ row }) => {
            const dateValue = row.getValue("periodEnd") as string;
            const formattedDate = new Date(dateValue).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            });
            return <div className="lowercase flex ml-10">{formattedDate}</div>;
        }
    },
    {
        accessorKey: "info", // Use "info" as the accessor key
        header: ({ column }) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Payment Method
                    <CaretSortIcon className="ml-2 h-4 w-4" />
                </Button>
            );
        },
        cell: ({ row }) => {
            const info = row.original.info; // Access the entire "info" object
            return (
                <div className="lowercase flex ml-10">
                    {info ? info.brand : "No brand specified"}
                </div>
            );
        },
    },
    {
        accessorKey: "amount",
        header: ({ column }) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Amount
                    <CaretSortIcon className="ml-2 h-4 w-4" />
                </Button>
            )
        },
        cell: ({ row }) => <div className="lowercase flex ml-10">{row.getValue("amount")}</div>,
    },
    {
        accessorKey: "status",
        header: ({ column }) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Status
                    <CaretSortIcon className="ml-2 h-4 w-4" />
                </Button>
            )
        },
        cell: ({ row }) => <div className="lowercase flex ml-10">
            <span className="border border-gray-800  bg-green-300 rounded-full px-2">
                {row.getValue("status")}
            </span>
        </div>,
    }
]

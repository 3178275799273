import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../reducers/auth-reducers';
import dashboardReducer from '../reducers/dashboard-reducer';
import uploadReducers from '../reducers/upload-reducers';
import loadingReducer from '../reducers/loader-reducer';
import ToastMiddleware from '../middleware/toast-middleware';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import userSettingReducers from '../reducers/userSetting-reducers';
import chatReducers from '../reducers/chat-reducers';
import subcriptionReducer from '../reducers/subcription-reducer';
import userManageReducers from '../reducers/userManage-reducers';
import apiKeyReducers from '../reducers/apiKey-reducers';
import teamManageReducers from '../reducers/teamManage-reducers';
import searchCockpitReducers from '../reducers/searchCockpit-reducers';

// Persist configuration for a specific reducer (e.g., auth)
const uploadPersistConfig = {
    key: 'upload',
    storage,
};

// Persist the auth reducer
const persistedUploadReducer = persistReducer(uploadPersistConfig, uploadReducers);

// Persist configuration for a specific reducer (e.g., auth)
const dashboardPersistConfig = {
    key: 'dashboard',
    storage,
};

// Persist the auth reducer
const persistedDashboardReducer = persistReducer(dashboardPersistConfig, dashboardReducer);


export const store = configureStore({
    reducer: {
        auth: authReducer,
        upload: persistedUploadReducer,
        dashboard: persistedDashboardReducer,
        loading: loadingReducer,
        userSetting: userSettingReducers,
        chat: chatReducers,
        subcriptionPlan:subcriptionReducer,
        userManage:userManageReducers,
        apiKeyManage:apiKeyReducers,
        teamManage: teamManageReducers,
        searchCockpit:searchCockpitReducers

    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(ToastMiddleware)
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
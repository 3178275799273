import { cn } from "../../../core/lib/utils"

import GDoc from '../../../assets/images/Documents/1doc.png'
import Doc from '../../../assets/images/Documents/2doc.png'
import Docx from '../../../assets/images/Documents/3doc.png'
import Odt from '../../../assets/images/Documents/4doc.png'
import Pdf from '../../../assets/images/Documents/5doc.png'
import Rtf from '../../../assets/images/Documents/6doc.png'
import Txt from '../../../assets/images/Documents/7doc.png'

import GSheet from '../../../assets/images/Spreadsheets/1SS.png'
import Xls from '../../../assets/images/Spreadsheets/2SS.png'
import Xlsx from '../../../assets/images/Spreadsheets/3SS.png'
import Csv from '../../../assets/images/Spreadsheets/4SS.png'
import Tsv from '../../../assets/images/Spreadsheets/5SS.png'
import Ods from '../../../assets/images/Spreadsheets/6SS.png'


import jpg from '../../../assets/images/Images/1Im.png'
import jpeg from '../../../assets/images/Images/2Im.png'
import png from '../../../assets/images/Images/3Im.png'
import gif from '../../../assets/images/Images/4Im.png'
import bmp from '../../../assets/images/Images/5Im.png'
import tif from '../../../assets/images/Images/6Im.png'
import svg from '../../../assets/images/Images/7Im.png'

import json from '../../../assets/images/Others/1Others.png'
import html from '../../../assets/images/Others/2Others.png'
import xml from '../../../assets/images/Others/3Others.png'
import zip from '../../../assets/images/Others/5Others.png'
import psd from '../../../assets/images/Others/6Others.png'
import ai from '../../../assets/images/Others/7Others.png'
import sketch from '../../../assets/images/Others/7Others.png'


import gslides from '../../../assets/images/Presentations/1PPT.png'
import ppt from '../../../assets/images/Presentations/2PPT.png'
import pptx from '../../../assets/images/Presentations/3PPT.png'
import odp from '../../../assets/images/Presentations/4PPT.png'
import { FileWarning } from "lucide-react"


interface FileIconViewerProps {
    file?: any;
    className?: string
}
const FileIconViewer: React.FC<FileIconViewerProps> = ({ file, className }) => {
    let fileSrc
    const parts = file?.fileName.split('.');
    const fileExtention = parts[parts.length - 1].toLowerCase()
    switch (fileExtention) {
        case 'gdoc':
            fileSrc = GDoc
            break;
        case 'doc':
            fileSrc = Doc
            break;
        case 'docx':
            fileSrc = Docx
            break;
        case 'odt':
            fileSrc = Odt
            break;
        case 'pdf':
            fileSrc = Pdf
            break;
        case 'rtf':
            fileSrc = Rtf
            break;
        case 'txt':
            fileSrc = Txt
            break;
        case 'gsheet':
            fileSrc = GSheet
            break;
        case 'xls':
            fileSrc = Xls
            break;
        case 'xlsx':
            fileSrc = Xlsx
            break;
        case 'csv':
            fileSrc = Csv
            break;
        case 'tsv':
            fileSrc = Tsv
            break;
        case 'ods':
            fileSrc = Ods
            break;
        case 'jpg':
            fileSrc = jpg
            break;
        case 'jpeg':
            fileSrc = jpeg
            break;
        case 'png':
            fileSrc = png
            break;
        case 'gif':
            fileSrc = gif
            break;
        case 'bmp':
            fileSrc = bmp
            break;
        case 'tiff':
            fileSrc = tif
            break;
        case 'svg':
            fileSrc = svg
            break;
        case 'gslides':
            fileSrc = gslides
            break;
        case 'ppt':
            fileSrc = ppt
            break;
        case 'pptx':
            fileSrc = pptx
            break;
        case 'odp':
            fileSrc = odp
            break;
        case 'json':
            fileSrc = json
            break;
        case 'html':
            fileSrc = html
            break;
        case 'xml':
            fileSrc = xml
            break;
        case 'zip':
            fileSrc = zip
            break;
        case 'psd':
            fileSrc = psd
            break;
        case 'ai':
            fileSrc = ai
            break;
        case 'sketch':
            fileSrc = sketch
            break;
        default:
            fileSrc = null
            break;
    }
    return (
        fileSrc ?
            <img className={cn('object-contain object-center', className)} src={fileSrc} alt="file" />
            : <FileWarning className="size-12" />
    )
}


export default FileIconViewer
import { Button } from "../../../core/components/ui/button"
import { Label } from "../../../core/components/ui/label"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../../core/components/ui/card"
import { Input } from "../../../core/components/ui/input"
import { AppDispatch } from "../../../redux-store/stores/store"
import { useDispatch } from "react-redux"
import { useForm } from "react-hook-form"
import { fetchUserData } from "../../../shared/services/manager"
import { resetPassword } from "../../../shared/services/userSetting"

export const ResetPassword = () => {
    const dispatch: AppDispatch = useDispatch()
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm()

    const userDetails = fetchUserData();

    const newPassword = watch('newPassword');

    const submitForm = (payload: any) => {
        const userId = userDetails?.userId;
        const newPayload = {
            newcredentials: payload,
            userId: userId
        };
        dispatch(resetPassword(newPayload));
        reset()
    }

    return (
        <div className="grids gap-6">
            <Card x-chunk="dashboard-04-chunk-2">
                <CardHeader>
                    <CardTitle>Password Settings</CardTitle>
                    <CardDescription>
                        Change your existing password.
                    </CardDescription>
                </CardHeader>
                <CardContent>
                    <form onSubmit={handleSubmit(submitForm)} className="space-y-2">
                        <div>
                            <Label htmlFor="currentPassword">Current Password</Label>
                            <Input
                                id="currentPassword"
                                type="password"
                                placeholder="Enter your current password"
                                {...register('currentPassword', { required: 'Your current password is required' })}
                            />
                            {errors.currentPassword && <p className="text-xs text-red-600">{errors.currentPassword.message?.toString()}</p>}
                        </div>
                        <div>
                            <Label htmlFor="newPassword">New Password</Label>
                            <Input
                                id="newPassword"
                                type="password"
                                placeholder="Enter your new password"
                                {...register('newPassword', {
                                    required: 'Password is required',
                                    minLength: {
                                        value: 8,
                                        message: 'Password must be at least 8 characters long'
                                    }
                                })}
                            />
                            {errors.newPassword && <p className="text-xs text-red-600">{errors.newPassword.message?.toString()}</p>}
                        </div>
                        <div>
                            <Label htmlFor="confirmPassword">Confirm Password</Label>
                            <Input
                                id="confirmPassword"
                                type="password"
                                placeholder="Re enter your new password"
                                {...register('confirmPassword', {
                                    required: 'Please confirm your password',
                                    validate: (value) =>
                                        value === newPassword || 'Passwords do not match',
                                })}
                            />
                            {errors.confirmPassword && <p className="text-xs text-red-600">{errors.confirmPassword.message?.toString()}</p>}
                        </div>
                        <CardFooter className="justify-end px-6 py-4">
                            <Button type="submit">Update</Button>
                        </CardFooter>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
}
import { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../../../core/components/ui/dialog";
import { Button } from "../../../core/components/ui/button";
import { Label } from "../../../core/components/ui/label";
import { Input } from "../../../core/components/ui/input";
import { useForm } from "react-hook-form";
import { AppDispatch } from "../../../redux-store/stores/store";
import { useDispatch } from "react-redux";
import { createTeam, updateTeam } from "../../../shared/services/teamService";
import { Users } from '../../../shared/models/userManage-model';
import { toast } from 'react-toastify';
import { Team } from "../../../shared/models/team-model";

interface TeamFormProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    editMode: boolean;
    team: Team | null | undefined;
    users: Users[] | null;
}

const TeamForm: React.FC<TeamFormProps> = ({ open, setOpen, editMode, team, users }) => {
    const dispatch: AppDispatch = useDispatch();
    const [initialUserGroupModels, setInitialUserGroupModels] = useState<number[]>([]);
    const [initialGroupName, setInitialGroupName] = useState<string>('');
    const { register, handleSubmit, reset, setValue, formState: { errors, isDirty, isValid } } = useForm({
        mode: 'onChange',
        defaultValues: {
            groupName: '',
            userGroupModels: [],
        },
    });


    useEffect(() => {
        if (editMode && team) {

            const userGroupModelsId = team?.userGroupModels.map((u: any) => u.userId);
            reset({
                groupName: team?.groupName,
                userGroupModels: userGroupModelsId
            });
            setInitialUserGroupModels(userGroupModelsId);
            setInitialGroupName(team?.groupName || '')

        } else {
            reset({
                groupName: '',
                userGroupModels: [],
            });
            setInitialGroupName('');
        }
    }, [editMode, team, reset]);

    const submitForm = (data: any) => {
        const payload = {
            ...data,
            userGroupModels: data.userGroupModels.map((adduserId: number | string) => {
                const userId = Number(adduserId);
                const user = users?.find(u => u.userId === userId);

                return {
                    userId: userId,
                    fullName: user ? user.fullName : '',
                };
            }),
        };

        dispatch(createTeam(payload));
        toast.success("Team added successfully");

        setOpen(false);
        reset();
    };

    const submitUpdateTeamForm = (data: any) => {
        const newlyAddedUsers = data.userGroupModels.filter(
            (userId: number | string) => !initialUserGroupModels.includes(Number(userId))
        );

        const removedUsers = initialUserGroupModels.filter(
            (userId: number) => !data.userGroupModels.includes(userId.toString())
        );

        const addUserGroupModel = newlyAddedUsers.map((userId: number | string) => {
            const user = users?.find(u => u.userId === Number(userId));
            return {
                userId: Number(userId),
                fullName: user ? user.fullName : ''
            };
        });

        const removeUserGroupModel = removedUsers.map((userId: number) => {
            const user = users?.find(u => u.userId === userId);
            return {
                userId: userId,
                fullName: user ? user.fullName : ''
            };
        });

        const groupNameChanged = data.groupName !== initialGroupName;

        if (groupNameChanged || addUserGroupModel.length > 0 || removeUserGroupModel.length > 0) {
            const payload = {
                groupName:groupNameChanged ? data.groupName : undefined,
                addUserGroupModel,
                removeUserGroupModel,
                groupId:team?.id
            };

            dispatch(updateTeam(payload));

            toast.success("Team updated successfully");
        } else {
            toast.info("No changes made to the team.");
        }
        setOpen(false);
        reset();
    };


    const handleDialogClose = () => {
        setOpen(false);
        reset();
    };

    return (
        <Dialog open={open} onOpenChange={handleDialogClose}>
            <DialogContent className="sm:max-w-[600px] bg-white">
                <DialogHeader className="flex flex-col items-center justify-center">
                    <DialogTitle className="font-bold text-2xl">{editMode ? 'Edit Team' : 'Add New Team'}</DialogTitle>
                    <DialogDescription></DialogDescription>
                </DialogHeader>
                <form onSubmit={handleSubmit(editMode ? submitUpdateTeamForm : submitForm)} className="space-y-4">

                    <div>
                        <Label className="block text-sm font-medium">Team Name</Label>
                        <Input
                            id="groupName"
                            type="text"
                            className="mt-1 block w-full p-2 border border-gray-300 rounded"
                            {...register('groupName', { required: 'Team name is required' })}
                        />
                        {errors.groupName && <span className="text-xs text-red-600">{errors.groupName.message}</span>}
                    </div>

                    <div>
                        <Label className="block text-sm font-medium">Select Users</Label>
                        <div className="mt-2">
                            {/* Check if 'users' is defined and is an array */}
                            {users && users.length > 0 ? (
                                users.map((user) => (
                                    <div key={user.userId} className="flex items-center mb-2">
                                        <input
                                            type="checkbox"
                                            id={`user-${user.userId}`}
                                            value={user.userId}
                                            {...register("userGroupModels")}
                                            defaultChecked={team?.userGroupModels.some(
                                                (selectedUser: any) => selectedUser.userId === user.userId
                                            )}
                                        />
                                        <Label htmlFor={`user-${user.userId}`} className="ml-2">{user.fullName}</Label>
                                    </div>
                                ))
                            ) : (
                                <span>No users available.</span>
                            )}
                        </div>
                        {errors.userGroupModels && <span className="text-xs text-red-600">{errors.userGroupModels.message}</span>}
                    </div>



                    <div className="flex flex-col justify-end items-end">
                        <Button type="submit" disabled={!isDirty || !isValid}>
                            {editMode ? 'Update Team' : 'Add Team'}
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default TeamForm;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '.';



export const getSubcriptionPlans = createAsyncThunk(
  'api/SubcriptionPlan',
  async (_, { rejectWithValue }) => {
    const api = axiosInstance
    try {
      const { data } = await api.get('/subscriptions');
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)

export const checkOutsubcription = createAsyncThunk(
  'api/checkOutSubcription',
  async (checkoutIds: any, { rejectWithValue }) => {
    const api = axiosInstance
    try {
      console.log(checkoutIds);
      const { data } = await api.post(`Subscriptions/create-customer-session?subcriptionId=${checkoutIds.subcriptionId}&priceId=${checkoutIds.priceId}&isTrial=${checkoutIds.isTrail}&productId=${checkoutIds.productId}`);
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)

export const getPaymentMethod = createAsyncThunk(
  'api/CardDetails',
  async (_, { rejectWithValue }) => {
    const api = axiosInstance
    try {
      const { data } = await api.get('/Subscriptions/user/payment-methods');
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)

export const getHistory = createAsyncThunk(
  'api/payment-History',
  async (_, { rejectWithValue }) => {
    const api = axiosInstance
    try {
      const { data } = await api.get('/Subscriptions/get-payment-history');
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)
export const getSubcriptionPlan = createAsyncThunk(
  'api/subscription-plan',
  async (_, { rejectWithValue }) => {
    const api = axiosInstance
    try {
      const { data } = await api.get('/Subscriptions/company/subscription');
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)

export const addPaymentMethod = createAsyncThunk(
  'api/addPaymentMethod',
  async (tokenId: any, { rejectWithValue }) => {
    const api = axiosInstance
    try {
      const { data } = await api.post(`/Subscriptions/payment-methods/${tokenId}`, {});
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)

export const setDefaultPayment = createAsyncThunk(
  'api/setDefaultMethod',
  async (id: any, { rejectWithValue }) => {
    const api = axiosInstance
    try {
      const { data } = await api.post(`/Subscriptions/set-user-default-payment-methods/${id}`, {});
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)
export const removeSubcripption = createAsyncThunk(
  'api/deleteMethod',
  async (planId: any, { rejectWithValue }) => {
    const api = axiosInstance
    try {
      const { data } = await api.delete(`/Subscriptions/${planId}`);
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)

export const reactiveSubcription = createAsyncThunk(
  'api/reactivateMethod',
  async (planId: any, { rejectWithValue }) => {
    const api = axiosInstance
    try {
      const { data } = await api.post(`/Subscriptions/reactivate-subscriptions/${planId}`,{});
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)




export const deletePaymentMethod = createAsyncThunk(
  'api/deleteMethod',
  async (cardId: any, { rejectWithValue }) => {
    const api = axiosInstance
    try {
      const { data } = await api.delete(`/Subscriptions/payment-methods/${cardId}`);
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
)

